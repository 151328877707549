import {Injectable} from '@angular/core';

export interface AddHreflangTagsInterface {uk: string, ru: string, en: string}

@Injectable({
  providedIn: 'root'
})
export class AddHreflangTagsService {

  constructor() {
  }

  /**
   * Додає hreflang теги у <head> як <link rel="alternate">
   */
  public addHreflangTags(baseUrls: AddHreflangTagsInterface, document: Document): void {
    const alternateLinks = [
      {hreflang: 'uk-UA', href: baseUrls.uk},
      {hreflang: 'uk', href: baseUrls.uk},
      {hreflang: 'ru-UA', href: baseUrls.ru},
      {hreflang: 'ru-KZ', href: baseUrls.ru},
      {hreflang: 'ru', href: baseUrls.ru},
      {hreflang: 'en', href: baseUrls.en},
      {hreflang: 'x-default', href: baseUrls.en}
    ];

    alternateLinks.forEach(link => {
      // Створюємо елемент <link> для hreflang
      const linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'alternate');
      linkElement.setAttribute('hreflang', link.hreflang);
      linkElement.setAttribute('href', link.href);

      // Додаємо елемент до <head>
      document.head.appendChild(linkElement);
    });
  }
}
